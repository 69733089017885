var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.roomInfo)?_c('div',{staticClass:"shuffled game-room"},[_c('app-menuBar',{attrs:{"roomInfo":_vm.roomInfo,"tempExtensionData":_vm.tempExtensionData,"customOptions":_vm.customOptions,"monetizedByUser":_vm.monetizedByUser,"routeRoomID":_vm.$route.params.roomID,"dataReady":_vm.dataReady,"firebaseReady":_vm.firebaseReady},on:{"roomMonetized":function($event){return _vm.$emit('roomMonetized', true)}}},[(!_vm.customOptions.facilitatorMode || _vm.userRole == 'facilitator')?_c('div',{staticClass:"row menu-row"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.reshuffleConfirm",modifiers:{"reshuffleConfirm":true}}],staticClass:"btn-lg btn-block control-button-restart",attrs:{"variant":"outline-dark","disabled":_vm.roomInfo.xCardIsActive,"color":"rgb(187, 138, 200)"},on:{"click":function($event){return _vm.closeMenu()}}},[_vm._v("Restart")])],1):_vm._e(),(!_vm.roomInfo.xCardIsActive)?_c('div',{staticClass:"row menu-row"},[_c('b-button',{staticClass:"btn-lg btn-block control-button-safety-card",attrs:{"variant":"outline-dark"},on:{"click":function($event){_vm.xCard(); _vm.closeMenu();}}},[_vm._v(_vm._s(_vm.customOptions.safetyCardButton ? _vm.customOptions.safetyCardButton : 'X-Card'))])],1):_vm._e(),_c('div',{staticClass:"row menu-row"},[(
          this.customOptions.showNextDeckButton &&
          (!_vm.customOptions.facilitatorMode || _vm.userRole == 'facilitator')
        )?_c('b-button',{staticClass:"control-button-next-deck btn-lg btn-block",attrs:{"variant":"outline-dark","disabled":_vm.roomInfo.xCardIsActive ||
  _vm.roomInfo.currentCardIndex >= _vm.roomInfo.locationOfLastCard},on:{"click":function($event){_vm.nextDeck();
_vm.closeMenu();}}},[_vm._v(_vm._s(_vm.customOptions.showNextDeckButton ? _vm.customOptions.showNextDeckButton : 'Next Deck'))]):_vm._e()],1),(_vm.customOptions.treatLastCardAsLastDeck)?_c('div',{staticClass:"row menu-row",attrs:{"disabled":_vm.roomInfo.currentCardIndex >= _vm.roomInfo.locationOfLastCard}},[_c('b-button',{staticClass:"control-button-last-deck btn-lg btn-block",attrs:{"variant":"outline-dark","disabled":_vm.roomInfo.xCardIsActive ||
          _vm.roomInfo.currentCardIndex == _vm.gSheet.length - 1 ||
          _vm.roomInfo.currentCardIndex == _vm.roomInfo.locationOfLastCard},on:{"click":function($event){_vm.lastCard();
_vm.closeMenu();}}},[_vm._v(" Go to "+_vm._s(_vm.customOptions.lastCardLabel)+" ")])],1):_vm._e(),(
        !_vm.customOptions.treatLastCardAsLastDeck &&
        !this.customOptions.showNextDeckButton &&
        (!_vm.customOptions.facilitatorMode || _vm.userRole == 'facilitator') &&
        !_vm.customOptions.hideNavigationButtons
      )?_c('div',[((!_vm.customOptions.hideLastCardOptions || _vm.renderChapterNavigation))?_c('hr',{staticClass:"mb-4"}):_vm._e(),(!_vm.customOptions.hideLastCardOptions)?_c('h6',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.customOptions.lastCardLabel)+" Options")]):_vm._e(),(!_vm.customOptions.hideLastCardOptions)?_c('div',{staticClass:"row menu-row"},[_c('b-button',{staticClass:"btn-block",attrs:{"disabled":_vm.roomInfo.xCardIsActive ||
            _vm.roomInfo.currentCardIndex == _vm.gSheet.length - 1 ||
            _vm.roomInfo.currentCardIndex == _vm.roomInfo.locationOfLastCard},on:{"click":function($event){_vm.lastCard();
_vm.closeMenu();}}},[_vm._v(" Go to "+_vm._s(_vm.customOptions.lastCardLabel)+" ")]),_c('b-button',{staticClass:"btn-block",attrs:{"disabled":_vm.roomInfo.xCardIsActive ||
            _vm.roomInfo.currentCardIndex == _vm.gSheet.length - 1 ||
            _vm.roomInfo.currentCardIndex == _vm.roomInfo.locationOfLastCard},on:{"click":function($event){_vm.shuffleLastCard('center');
_vm.closeMenu();}}},[_vm._v(" Shuffle near middle ")]),_c('b-button',{staticClass:"btn-block",attrs:{"disabled":_vm.roomInfo.xCardIsActive ||
            _vm.roomInfo.currentCardIndex == _vm.gSheet.length - 1 ||
            _vm.roomInfo.currentCardIndex == _vm.roomInfo.locationOfLastCard},on:{"click":function($event){_vm.shuffleLastCard('end');
_vm.closeMenu();}}},[_vm._v(" Shuffle near end ")])],1):_vm._e()]):_vm._e(),(
        _vm.renderChapterNavigation &&
        (!_vm.customOptions.facilitatorMode || _vm.userRole == 'facilitator')
      )?_c('div',{staticClass:"mb-4"},[(_vm.customOptions.chapterNavHeader)?_c('h6',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.customOptions.chapterNavHeader)+" ")]):_vm._e(),_vm._l((_vm.chapterNavLabels),function(chapter){return _c('div',{key:chapter.label,staticClass:"row menu-row"},[_c('b-button',{staticClass:"btn-block",attrs:{"disabled":_vm.roomInfo.xCardIsActive},on:{"click":function($event){_vm.goToCard(parseInt(_vm.deckIndex[chapter.deckNumber]));
          _vm.closeMenu();}}},[_vm._v(" "+_vm._s(chapter.label)+" ")])],1)})],2):_vm._e()]),(_vm.firebaseCacheError)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v("Warning: the length of the deck has changed since this room was first created. Click Restart to resync card data.")]):_vm._e(),(_vm.customOptions.demoInfo)?_c('b-alert',{staticClass:"demoInfo",attrs:{"show":"","variant":"info"}},[_vm._v("This demo is powered by "),_c('a',{attrs:{"href":_vm.customOptions.demoInfo,"target":"_blank"}},[_vm._v("this Google Sheet Template")]),_vm._v(". Copy the sheet and start editing it to design your own game! ")]):_vm._e(),_vm._t("upper-extensions"),(_vm.roomInfo)?_c('div',{},[_c('div',{staticClass:"before-game-card"},[_c('transition',{attrs:{"name":"fade"}},[(
            (!_vm.customOptions.facilitatorMode || _vm.userRole == 'facilitator') &&
            (!_vm.customOptions.hideNavigationButtons ||
              parseInt(_vm.customOptions.hideNavigationButtons) >
              _vm.roomInfo.currentCardIndex)
          )?_c('div',{staticClass:"fab-buttons container"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-outline-dark btn-fab btn-fab-left control-button-previous-card shadow",attrs:{"title":"Previous Card","disabled":_vm.roomInfo.xCardIsActive || _vm.roomInfo.currentCardIndex == 0 ||
                (_vm.roomInfo.extensionData && (_vm.roomInfo.extensionData.interruptsReplaceMainCards || _vm.roomInfo.extensionData.interruptsPauseNavigation) && _vm.roomInfo.extensionData.currentInterrupt)},on:{"click":function($event){return _vm.previousCard()}}},[_c('b-icon',{staticClass:"h1 mb-0",attrs:{"icon":"chevron-left"}}),_c('b-icon',{staticClass:"h1 mb-0 mr-2",attrs:{"icon":"card-heading"}})],1),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-outline-dark btn-fab btn-fab-right control-button-next-card shadow",attrs:{"title":"Next Card","disabled":_vm.roomInfo.xCardIsActive ||
                _vm.roomInfo.currentCardIndex >= _vm.roomInfo.locationOfLastCard ||
                (_vm.customOptions.treatLastCardAsLastDeck &&
                  this.roomInfo.cardSequence.indexOf(
                    this.unorderedDecks[this.unorderedDecks.length - 1][0]
                  ) == this.roomInfo.currentCardIndex) ||
                (_vm.roomInfo.extensionData && (_vm.roomInfo.extensionData.interruptsReplaceMainCards || _vm.roomInfo.extensionData.interruptsPauseNavigation) && _vm.roomInfo.extensionData.currentInterrupt)},on:{"click":function($event){return _vm.nextCard()}}},[(_vm.roomInfo.currentCardIndex == 0)?_c('div',[_c('b-icon',{staticClass:"h1 mb-0 ml-2",attrs:{"animation":"fade","icon":"card-heading"}}),_c('b-icon',{staticClass:"h1 mb-0",attrs:{"animation":"fade","icon":"chevron-right"}})],1):_c('div',[_c('b-icon',{staticClass:"h1 mb-0 ml-2",attrs:{"icon":"card-heading"}}),_c('b-icon',{staticClass:"h1 mb-0",attrs:{"icon":"chevron-right"}})],1)])]):_vm._e()]),(
          _vm.customOptions.displayCardCount &&
          _vm.customOptions.displayCardCount - 1 <= _vm.roomInfo.currentCardIndex &&
          _vm.roomInfo.currentCardIndex < _vm.roomInfo.locationOfLastCard
        )?_c('div',{staticClass:"row mb-3 game-meta card-counter"},[_c('div',{staticClass:"col-sm"},[_c('h2',[(_vm.customOptions.displayCardCountLabel)?_c('span',[_vm._v(_vm._s(_vm.customOptions.displayCardCountLabel))]):_c('span',[_vm._v("Cards seen:")]),_vm._v(" "+_vm._s(_vm.roomInfo.currentCardIndex - _vm.customOptions.displayCardCount + 1)+" ")])])]):_vm._e()],1),(_vm.roomInfo.xCardIsActive)?_c('div',{staticClass:"card main-card d-flex shadow img-fluid mb-4"},[_c('div',{staticClass:"card-body align-items-center justify-content-center",class:{
          'card-body': !_vm.customOptions.cardBackgroundImage,
          'card-img-overlay':
            _vm.customOptions.cardBackgroundImage &&
            !_vm.customOptions.cardBackgroundImageAlign,
        }},[_c('div',{staticClass:"mt-5 pt-5 mb-5"},[(!_vm.customOptions.safetyCardText)?_c('h1',[_vm._v("X-Card")]):_vm._e(),(_vm.customOptions.safetyCardText)?_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.customOptions.safetyCardText),expression:"customOptions.safetyCardText"}],staticClass:"safety-card-text"}):_vm._e()]),_c('button',{staticClass:"btn btn-outline-dark mt-5",on:{"click":function($event){return _vm.xCard()}}},[_vm._v(" Continue ")]),(!_vm.customOptions.safetyCardText)?_c('div',{},[_c('a',{staticClass:"x-card-text",attrs:{"href":"http://tinyurl.com/x-card-rpg"}},[_vm._v("About the X-Card")])]):_vm._e()])]):_vm._e(),_vm._l((_vm.roomInfo.cardSequence),function(card,cardIndex){return _c('div',{key:cardIndex},[(
  _vm.gSheet[_vm.roomInfo.cardSequence[_vm.roomInfo.currentCardIndex]] &&
  cardIndex == _vm.roomInfo.currentCardIndex &&
  (!_vm.roomInfo.extensionData || !(_vm.roomInfo.extensionData.interruptsReplaceMainCards && _vm.roomInfo.extensionData.currentInterrupt)) &&
  !_vm.roomInfo.xCardIsActive
)?_c('div',{staticClass:"card main-card d-flex shadow img-fluid mb-4",class:{
          'bg-transparent':
            _vm.customOptions.coverImage &&
            !_vm.customOptions.noCoverImageInSession &&
            cardIndex == 0,
        }},[(
            _vm.customOptions.coverImage &&
            !_vm.customOptions.noCoverImageInSession &&
            _vm.roomInfo.currentCardIndex == 0
          )?_c('img',{staticClass:"card-img-top",staticStyle:{"width":"100%"},attrs:{"src":_vm.customOptions.coverImage}}):_vm._e(),(
              _vm.customOptions.cardBackgroundImage &&
              (!_vm.customOptions.coverImage ||
                _vm.roomInfo.currentCardIndex != 0 ||
                _vm.customOptions.noCoverImageInSession) &&
              !_vm.customOptions.cardBackgroundImageAlign
            )?_c('img',{staticClass:"card-img-top card-background",staticStyle:{"width":"100%"},attrs:{"src":_vm.customOptions.cardBackgroundImage}}):_vm._e(),(
            _vm.customOptions.cardBackgroundImageAlign == 'top' &&
            _vm.roomInfo.currentCardIndex != 0
          )?_c('b-card-img',{attrs:{"src":_vm.customOptions.cardBackgroundImage,"alt":"Card Background image","top":""}}):_vm._e(),(
            _vm.customOptions.instructionsProgressBar &&
            _vm.roomInfo.currentCardIndex < _vm.firstNonInstruction &&
            _vm.roomInfo.currentCardIndex != 0
          )?_c('div',{staticClass:"row mt-4 mx-4 game-meta"},[_c('div',{staticClass:"col-sm"},[_c('h3',[_vm._v("Instructions")]),_c('b-progress',{attrs:{"value":_vm.roomInfo.currentCardIndex,"max":_vm.firstNonInstruction - 1,"variant":"dark"}})],1)]):_vm._e(),(
            !_vm.customOptions.coverImage ||
            _vm.roomInfo.currentCardIndex != 0 ||
            _vm.customOptions.noCoverImageInSession
          )?_c('div',{class:_vm.gSheet[_vm.roomInfo.cardSequence[_vm.roomInfo.currentCardIndex]].deckNumberClass},[_c('div',{staticClass:"card-body justify-content-center d-flex align-items-center mt-4",class:{
              'card-img-overlay':
                _vm.customOptions.cardBackgroundImage &&
                !_vm.customOptions.cardBackgroundImageAlign,
            }},[(!_vm.roomInfo.showCardBack)?_c('div',[(!_vm.customOptions.hideHeadersOnCards)?_c('h1',{staticClass:"main-card-header-text"},[_vm._v(" "+_vm._s(_vm.gSheet[_vm.roomInfo.cardSequence[_vm.roomInfo.currentCardIndex]] .headerText)+" ")]):_vm._e(),_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
                  _vm.gSheet[_vm.roomInfo.cardSequence[_vm.roomInfo.currentCardIndex]]
                    .bodyText
                ),expression:"\n                  gSheet[roomInfo.cardSequence[roomInfo.currentCardIndex]]\n                    .bodyText\n                "}],staticClass:"mt-4 mb-4 main-card-body-text"}),(
                  _vm.gSheet[_vm.roomInfo.cardSequence[_vm.roomInfo.currentCardIndex]]
                    .cardBack && _vm.customOptions.reversableCards
                )?_c('button',{staticClass:"btn btn-outline-dark",on:{"click":function($event){return _vm.flipCard()}}},[_c('svg',{staticClass:"bi bi-arrow-repeat",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"}}),_c('path',{attrs:{"fill-rule":"evenodd","d":"M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"}})])]):_vm._e()]):_c('div',[_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
                  _vm.gSheet[_vm.roomInfo.cardSequence[_vm.roomInfo.currentCardIndex]]
                    .cardBack
                ),expression:"\n                  gSheet[roomInfo.cardSequence[roomInfo.currentCardIndex]]\n                    .cardBack\n                "}],staticClass:"mt-4 mb-4"}),(
                  _vm.gSheet[_vm.roomInfo.cardSequence[_vm.roomInfo.currentCardIndex]]
                    .cardBack && _vm.customOptions.reversableCards
                )?_c('button',{staticClass:"btn btn-outline-dark",on:{"click":function($event){return _vm.flipCard()}}},[_c('svg',{staticClass:"bi bi-arrow-repeat",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"d":"M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"}}),_c('path',{attrs:{"fill-rule":"evenodd","d":"M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"}})])]):_vm._e()])])]):_vm._e(),(
            _vm.customOptions.lastCardReminderText &&
            _vm.customOptions.lastCardReminderFrequency &&
            _vm.roomInfo.currentCardIndex > _vm.firstNonInstruction &&
            (_vm.roomInfo.currentCardIndex - _vm.firstNonInstruction) %
            _vm.customOptions.lastCardReminderFrequency ==
            _vm.customOptions.lastCardReminderFrequency - 1
          )?_c('b-alert',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.customOptions.lastCardReminderText),expression:"customOptions.lastCardReminderText"}],staticClass:"mx-3",attrs:{"show":"","variant":"info"}}):_vm._e(),(
            _vm.customOptions.cardBackgroundImageAlign == 'bottom' &&
            _vm.roomInfo.currentCardIndex != 0
          )?_c('b-card-img',{attrs:{"src":_vm.customOptions.cardBackgroundImage,"alt":"Card Background image","bottom":""}}):_vm._e()],1):_vm._e()])}),_c('div',{staticClass:"after-game-card"},[(
          _vm.renderChapterNavigation && _vm.customOptions.showChapterNavOnPage &&
          (!_vm.customOptions.facilitatorMode || _vm.userRole == 'facilitator')
        )?_c('div',{staticClass:"mb-4 extension"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm"},[_c('div',{staticClass:"card d-flex"},[_c('div',{staticClass:"card-body"},[(_vm.customOptions.chapterNavHeader)?_c('h2',[_vm._v(" "+_vm._s(_vm.customOptions.chapterNavHeader)+" ")]):_vm._e(),_vm._l((_vm.chapterNavLabels),function(chapter){return _c('div',{key:chapter.label},[_c('b-button',{staticClass:"btn-block btn-default my-2 chapter-nav-button",attrs:{"disabled":_vm.roomInfo.xCardIsActive},on:{"click":function($event){_vm.goToCard(parseInt(_vm.deckIndex[chapter.deckNumber]))}}},[_vm._v(" "+_vm._s(chapter.label)+" ")])],1)})],2)])])])]):_vm._e(),_vm._t("lower-extensions"),_c('b-modal',{attrs:{"id":"modalNextDeckConfirm","title":"Advance?","hide-footer":""}},[_c('p'),_c('div',{staticClass:"text-center mb-3"},[_c('b-button',{attrs:{"variant":"dark"},on:{"click":function($event){return _vm.nextDeck()}}},[_vm._v("Advance to "+_vm._s(_vm.customOptions.showNextDeckButton ? _vm.customOptions.showNextDeckButton : "Next Deck"))])],1)]),_c('b-modal',{attrs:{"id":"reshuffleConfirm","title":"Restart and Reshuffle","hide-footer":""}},[_c('p',[_vm._v(" Do you want to reshuffle all of the prompts and restart the game? ")]),_c('div',{staticClass:"text-center mb-3"},[_c('b-button',{attrs:{"variant":"dark"},on:{"click":function($event){return _vm.shuffleAndResetGame()}}},[_vm._v("Restart and Reshuffle")])],1)])],2)],2):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }